<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin:: Add customer-->
    <div class="row gutter-b">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Form Customer</h6>
          </template>
          <div class="row">
            <div class="col-md-6">
              <div
                class="mb-4"
                v-for="(field, fieldIndex) in customerField"
                :key="fieldIndex"
              >
                <h6 class="mb-1">
                  {{ field.fieldName }}
                  <span class="text-danger" v-if="field.required">*</span>
                </h6>
                <b-form-input
                  v-model="customer[field.value]"
                  :placeholder="field.placeholder"
                  v-bind="field.bind"
                  :type="field.type"
                  :ref="field.value"
                ></b-form-input>
                <div class="text-danger" v-if="$v.customer[field.value].$error">
                  Tolong isi field
                  <span style="text-transform: lowercase">{{
                    field.fieldName
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">
                  Alamat Lengkap <span class="text-danger">*</span>
                </h6>
                <div>
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="customer.address"
                    placeholder="Masukan alamat lengkap"
                    rows="8"
                    style="height: 116px"
                    ref="address"
                  ></b-form-textarea>
                </div>
                <div class="text-danger" v-if="$v.customer.address.$error">
                  Tolong isi field address
                </div>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Provinsi</h6>
                <b-form-select
                  v-model="provinceSelected"
                  :options="optionProvince"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Provinsi</option>
                </b-form-select>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kabupaten/Kota</h6>
                <b-form-select
                  v-model="regencySelected"
                  :options="optionRegency"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Kabupaten</option>
                </b-form-select>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kecamatan</h6>
                <b-form-select
                  v-model="districtSelected"
                  :options="optionDistric"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Kecamatan</option>
                </b-form-select>
              </div>
              <div class="mb-4">
                <h6 class="mb-1">Kelurahan</h6>
                <b-form-select
                  v-model="customer.village_id"
                  :options="optionVillage"
                  value-field="id"
                  text-field="name"
                >
                  <option :value="null">Pilih Kelurahan</option>
                </b-form-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row justify-center gutter-b">
      <div class="col-md-2">
        <b-button variant="primary" class="w-100" @click="handleSubmit"
          >Submit</b-button
        >
      </div>
    </div>
    <!--end:: Add customer-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "Customer",
  data() {
    return {
      // PROFILE Customer
      previewUrl: null,
      customer: {
        name: "",
        address: "",
        province_id: null,
        district_id: null,
        pic_name: "",
        phone: "",
        email: "",
        regency_id: null,
        village_id: null,
        contract_number: "",
        contract_start: "",
      },
      customerField: [
        {
          fieldName: "Nama Client",
          required: true,
          placeholder: "Masukan nama client anda",
          value: "name",
          type: "text",
        },
        {
          fieldName: "Email",
          required: true,
          placeholder: "Masukan nama email anda",
          value: "email",
          type: "email",
        },
        {
          fieldName: "No Telpon",
          required: true,
          placeholder: "Masukan no telepon",
          value: "phone",
          type: "number",
        },
        {
          fieldName: "Nama PIC",
          required: true,
          placeholder: "Masukan nama PIC",
          value: "pic_name",
          type: "text",
        },
        {
          fieldName: "Tanggal Berlaku Perjanjian",
          required: true,
          placeholder: "",
          value: "contract_start",
          type: "date",
        },
        {
          fieldName: "No Kontrak",
          required: true,
          placeholder: "Masukan no kontrak anda",
          value: "contract_number",
          type: "number",
        },
      ],
      optionBloodType: [
        {
          value: "",
          text: "Pilih golongan darah",
        },
        {
          value: "A",
          text: "A",
        },
        {
          value: "AB",
          text: "AB",
        },
        {
          value: "B",
          text: "B",
        },
        {
          value: "O",
          text: "O",
        },
      ],
      optionCustomerBranch: [],
      optionProvince: [],
      optionRegency: [],
      optionDistric: [],
      optionVillage: [],
    };
  },
  validations: {
    customer: {
      name: {
        required,
      },
      address: {
        required,
      },
      pic_name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      contract_start: {
        required,
      },
      contract_number: {
        required,
      },
    },
  },
  components: {},
  computed: {
    ...mapGetters(["currentUser"]),
    provinceSelected: {
      get() {
        return this.customer.province_id;
      },
      set(value) {
        this.customer.province_id = value;
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/regencies/${value}`
          )
          .then(({ data }) => {
            this.optionRegency = data.list;
          });
      },
    },
    regencySelected: {
      get() {
        return this.customer.regency_id;
      },
      set(value) {
        this.customer.regency_id = value;
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/districts/${value}`
          )
          .then(({ data }) => {
            this.optionDistric = data.list;
          });
      },
    },
    districtSelected: {
      get() {
        return this.customer.district_id;
      },
      set(value) {
        this.customer.district_id = value;
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/villages/${value}`
          )
          .then(({ data }) => {
            this.optionVillage = data.list;
          });
      },
    },
  },
  mounted() {
    this.getData();
    let lastBreadcrumb = {};
    if (this.$route.name === "customer-edit") {
      this.getEditData();
      lastBreadcrumb = { title: "Edit Customer" };
    } else lastBreadcrumb = { title: "Add Customer" };
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Customer", route: "/customer" },
      lastBreadcrumb,
    ]);
  },
  methods: {
    handelFoto(e) {
      const file = e.target.files[0];
      this.previewUrl = URL.createObjectURL(file);
    },
    async getData() {
      try {
        const request = [
          this.$axios.get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/provinces`
          ),
        ];
        const [province] = await Promise.all(request);
        this.optionProvince = province.data.list;
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async handleSubmit() {
      this.$v.customer.$touch();
      if (!this.$v.customer.$invalid) {
        const payload = {
          ...this.customer,
          status: true,
        };
        try {
          if (this.$route.name === "customer-edit") {
            await this.$axios.put(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${this.$route.params.id}`,
              payload
            );
          } else {
            await this.$axios.post(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer`,
              payload
            );
          }
          this.$bvToast.toast("Successfully add customer", {
            title: "Successs",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "customer" });
        } catch ({ response }) {
          this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        if (this.$v.customer.name.$error) this.$refs["name"][0].focus();
        else if (this.$v.customer.email.$error) this.$refs["email"][0].focus();
        else if (this.$v.customer.phone.$error) this.$refs["phone"][0].focus();
        else if (this.$v.customer.contract_start.$error)
          this.$refs["contract_start"][0].focus();
        else if (this.$v.customer.contract_number.$error)
          this.$refs["contract_number"][0].focus();
        else if (this.$v.customer.address.$error) this.$refs["address"].focus();
      }
    },
    async getEditData() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${this.$route.params.id}`
        );
        this.customer = {
          name: data?.name || "",
          address: data?.address || "",
          province_id: data?.province?.id || null,
          district_id: data?.district?.id || null,
          phone: data?.phone || "",
          email: data?.email || "",
          regency_id: data?.regency?.id || null,
          village_id: data?.village?.id || null,
          contract_number: data?.contract_number || "",
          contract_start: data?.contract_start || "",
        };
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
